.preview {
    position: fixed;
    z-index: 99999999999;
    bottom: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
}
.notDefault {
    color: #f00;
}
.popup {
    z-index: 99999999999;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 30px 10px;
    margin-left: auto;
    border: 1px solid #999;
    border-radius: 3px;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
    min-width: 400px;
    background: #fff;
    padding: 14px 20px;
}
.label {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;
}
.input {
    border: 1px solid #999;
    border-radius: 3px;
    height: 32px;
    padding-left: 9px;
    width: 100%;
    max-width: 300px;
}
.checkbox {
    accent-color: #f00;
    width: 15px;
    height: 15px;
    margin: 0 4px 0 0;
    border: 1px solid #999;
    border-radius: 3px;
    vertical-align: middle;
}
