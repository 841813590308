/*------------------------------------*\
   main footer styles
\*------------------------------------*/
.mfooter {
    font-size: 16px;
    line-height: 1.2;
    &:not(.darkMode) {
        background: #f5f7fa;
    }
}
.top {
    clear: both;
    padding: 46px 0 0;
    overflow: hidden;
    position: relative;
    &:not(.darkMode) {
        &::before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            display: block;
            margin: -25px auto 0;
            max-width: 1200px;
            width: 100%;
            height: 25px;
            border-radius: 250px / 12px;
        }
    }
}
.darkMode {
    background: none;
    &::before {
        display: none;
    }
    .bottom {
        border-top: 1px solid rgb(211, 213, 217, 0.18);
    }
}
.bottom {
    border-top: 1px solid #d3d5d9;
}
