.btn {
    display: inline-block;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 1.05em 2.1em;
    line-height: 1;
    font-size: 18px;
    font-family: var(--roboto);
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fff;
    border: 2px solid var(--primary-btn-bg-color);
    color: var(--primary-btn-bg-color);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
    letter-spacing: 0.1em;
    &.text {
        background: transparent;
        border: none;
        padding: 1.05em 0;
        &.white {
            color: var(--primary-brand-color-alternate-400);
            &:hover,
            &:active,
            &:focus {
                background: transparent;
                border: none;
            }
        }
    }
    &.contained {
        border-radius: 50px;
        padding: 1.05em 2.1em;
        background: var(--primary-brand-color-alternate-400);
        border: 2px solid var(--primary-btn-bg-color);
        color: var(--primary-btn-bg-color);
    }
    &.solid {
        border-radius: 50px;
        background: var(--primary-brand-color-400);
        border: none;
        color: var(--primary-brand-color-alternate-400);
    }
    &.solidInverse {
        border-radius: 50px;
        background: var(--primary-brand-color-alternate-400);
        border: none;
        color: var(--primary-brand-color-400);

        &:hover,
        &:active,
        &:focus {
            background: var(--primary-brand-color-alternate-400);
            color: var(--primary-btn-hover-bg-color);
        }
    }

    &.outlined {
        border-radius: 50px;
        padding: 1.05em 2.1em;
        background: transparent;
        border: 2px solid var(--primary-btn-bg-color);
        color: var(--primary-btn-bg-color);

        &.white {
            border-color: var(--primary-brand-color-alternate-400);
            color: var(--primary-brand-color-alternate-400);
            &:hover,
            &:active,
            &:focus {
                border: 2px solid var(--primary-btn-bg-color);
            }
        }
    }

    &:hover,
    &:active,
    &:focus {
        background-color: var(--primary-btn-hover-bg-color);
        border-color: var(--primary-btn-hover-bg-color);
        color: var(--primary-btn-hover-text-color);
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
}

.blue {
    color: #fff;
    background: var(--primary-brand-color-400);
    border-color: var(--primary-brand-color-400);

    &:hover,
    &:active,
    &:focus {
        background: var(--primary-brand-color-600);
        border-color: var(--primary-brand-color-600);
    }
}
.yellow {
    background: var(--secondary-brand-color-400);
    border-color: var(--secondary-brand-color-400);
    color: #202730;

    &:hover,
    &:active,
    &:focus {
        background: var(--secondary-brand-color-600);
        border-color: var(--secondary-brand-color-600);
        color: #202730;
    }
}
.orange {
    background: var(--orange-color);
    border-color: var(--orange-color);
    color: var(--orange-button-text);
    &:hover,
    &:active,
    &:focus {
        background: var(--orange-color-hover);
        border-color: var(--orange-color-hover);
        color: var(--orange-button-text);
    }
}
.navy {
    background: #020730;
    border-color: #020730;
    color: #fff;
    &:hover,
    &:active,
    &:focus {
        background: #030d5c;
        border-color: #030d5c;
    }
}
.green {
    background: var(--green-color);
    border-color: var(--green-color);
    color: var(--green-button-text);
}

.green:hover,
.green:active,
.green:focus {
    background: var(--green-color-hover);
    border-color: var(--green-color-hover);
    color: var(--green-button-text);
}
.xlarge {
    font-size: 24px;
    padding: max(0.85em, 18.9px) max(1.7em, 37.8px);
    letter-spacing: 0;
}
.large {
    font-size: 18px;
    padding: max(0.85em, 18.9px) max(1.7em, 37.8px);
    letter-spacing: 0;
}
.medium {
    font-size: 18px;
    padding: max(1.1em, 14px) max(2.1em, 24px);
    letter-spacing: 0;
}
.small {
    font-size: 16px;
    padding: max(0.55em, 14px) max(1.3em, 24px);
}
.xsmall {
    font-size: 14px;
    padding: max(0.55em, 8px) max(1.3em, 18px);
}
.xxsmall {
    font-size: 13px;
    font-weight: 500;
    padding: max(0.85em, 8px) max(1.3em, 20px);
    letter-spacing: 0;
}
