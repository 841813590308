.columns {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0 10px;
    justify-content: flex-start;
    align-items: flex-start;
    & > div {
        padding: 0;
        padding-inline-end: 30px;
        box-sizing: border-box;
        margin-bottom: 20px;
        flex-basis: 50%;
        min-width: 200px;
        flex-grow: 1;
        &:nth-last-child(2) {
            flex-grow: 1;
        }
        @media screen and (min-width: 480px) {
            margin-bottom: 20px;
        }
        @media screen and (min-width: 768px) {
            flex-basis: 220px;
        }
        &:last-child {
            flex-grow: 1;
            flex-basis: 360px;
            padding: 0;
        }
    }
}
