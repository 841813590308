.apps {
    text-align: center;
    padding: 0;
    margin: 10px 0;
    box-sizing: border-box;
    h3 {
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 17px;
        line-height: 1.2;
        color: #000;
        text-align: start;
        margin: 0 0 20px;
        padding: 0;
    }
    .apps-list {
        list-style: none;
        display: flex;
        flex-flow: row nowrap;
        grid-gap: 20px;
        align-items: center;
        padding: 0;
        flex-grow: 1;
        flex-basis: 100%;
        @media screen and (min-width: 480px) {
            max-width: 254px;
            li {
                padding: 0;
                flex: 0 0 20%;
                a {
                    justify-content: flex-start;
                }
            }
        }
        @media screen and (min-width: 768px) {
            margin: 0;
        }
        li {
            padding: 0;
            box-sizing: border-box;
            display: flex;
            a {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    svg {
                        fill: var(--primary-link-focus-color, #00baff);
                    }
                }
            }
        }
    }
}
.darkMode {
    svg {
        fill: #cddbe5;
    }
    h3 {
        color: #fff;
    }
}
