.mfooter-lang-wrap {
    flex-basis: 50%;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0;
    margin: 10px 0;
    @media screen and (min-width: 768px) {
        flex-basis: auto;
        box-sizing: border-box;
    }
    h3 {
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 17px;
        line-height: 1.2;
        color: #000;
        text-align: start;
        margin: 0 0 20px;
        padding: 0;
    }
    .mfooter-lang-icon {
        display: inline-block;
        width: 23px;
        margin-right: 16px;
        color: #000;
        font-family: var(--rubik);
        font-size: 16px;
        font-weight: 500;
    }
    .mfooter-world-icon {
        margin-right: 7px;
    }
}
.mfooter-lang {
    font-family: var(--rubik);
    line-height: 1.2;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    vertical-align: text-bottom;
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:hover {
        color: var(--primary-link-focus-color, #00baff);
        text-decoration: none;
    }
    > span:not(.mfooter-popup) {
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
}
.mfooter-lang-change {
    background: #fff;
    box-shadow: 2px 2px 15px #00000010;
    border-radius: 12px;
    padding: 13px 15px;
    max-width: 400px;
    width: 100%;
    height: 48px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    @media screen and (min-width: 480px) {
        width: 230px;
    }
    &::after {
        content: '';
        background: url('./assets/arrow.svg') center center no-repeat;
        background-size: contain;
        width: 10px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 27px;
        transform: translateY(-50%) rotate(180deg);
    }
    &.visible::after {
        transform: translateY(-50%);
    }
}
.mfooter-popup {
    position: absolute;
    visibility: hidden;
    right: calc(50%);
    top: 30px;
    transform: translateY(-100%) translateX(50%);
    z-index: 1000;
    background: #fff;
    box-shadow: 2px 2px 15px #00000010;
    border-radius: 12px;
    padding: 13px 20px;
    width: 230px;
    box-sizing: border-box;
    line-height: 1.73;
    ul {
        margin: 0;
        padding: 8px 0;
        list-style: none;
        li {
            display: flex;
            margin-top: 8px;
            &:first-child {
                margin-top: 0;
            }
            a,
            span {
                display: inline-flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;
                white-space: nowrap;
                line-height: 1.5;
                color: #000;
                font-family: var(--rubik);
                font-size: 16px;
                font-weight: 500;
                &:hover {
                    color: var(--primary-link-focus-color);
                    .mfooter-lang-icon {
                        color: var(--primary-link-focus-color);
                    }
                }
            }
            span {
                color: var(--primary-link-focus-color);
                .mfooter-lang-icon {
                    color: var(--primary-link-focus-color);
                }
            }
        }
    }
    &-visible {
        visibility: visible;
    }
}

.darkMode {
    h3 {
        color: #fff;
    }
    .mfooter-lang {
        color: #cddbe5;
    }
    .mfooter-lang-change {
        background: #000;
        &::after {
            background-image: url('./assets/arrow-dark.svg');
        }
    }
    .mfooter-popup {
        background: #000;
        ul li a {
            color: #cddbe5;
            &:hover {
                color: var(--primary-link-focus-color);
            }
        }
    }
}
