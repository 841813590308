.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    &.supplemental {
        padding: 33px 10px 30px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        box-sizing: border-box;
    }
}
