.mfooter-bottom {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px 30px;
    box-sizing: border-box;
    text-align: center;
    flex-basis: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (min-width: 768px) {
        flex-basis: 100%;
        justify-content: space-between;
        display: flex;
        align-items: flex-end;
        flex-flow: row nowrap;
        text-align: left;
    }
}
