.action {
    font-family: var(--rubik);
    font-size: 12px;
    line-height: 1.4;
    color: #000;
    border: none;
    background: none;
    padding: 0;
    &:hover {
        cursor: pointer;
        color: var(--primary-link-focus-color, #00baff);
        .action {
            color: currentColor;
        }
        .icon {
            svg {
                fill: var(--primary-link-focus-color, #00baff);
            }
        }
    }
}
