.single-post {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 20px;
    text-decoration: none;
    &:hover {
        p {
            color: var(--primary-link-active-color);
        }
    }
}
.image {
    margin-inline-end: 22px;
    width: 102px;
    img {
        border-radius: 12px;
        box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.12);
        vertical-align: middle;
    }
}
.meta {
    flex-grow: 1;
    flex-basis: calc(100% - 108px - 22px);
    min-width: 230px;
    p {
        font-family: var(--rubik);
        font-size: 14px;
        font-weight: 400;
        color: #202730;
        text-align: start;
        line-height: 20px;
        margin: 0;
        padding: 0;
        display: flex;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 10px;
        @media screen and (min-width: 480px) {
            margin-top: 0;
        }
    }
}
.darkMode {
    .meta p {
        color: #cddbe5;
    }
    .single-post:hover p {
        color: var(--primary-link-active-color);
    }
}
