.mfooter-info {
    sup {
        top: -0.5em;
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 0 20px;
        li {
            font-family: var(--rubik);
            font-size: 12px;
            line-height: 1.4;
            color: #000;
        }
    }
}
.mfooter-text {
    display: block;
}
.mfooter-copyright {
    padding: 0;
    font-size: 12px;
    font-family: var(--rubik);
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    margin: 0;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        margin-inline-end: 20px;
    }
}
.mfooter-logo {
    display: inline-block;
    margin: 10px 0;
    @media screen and (min-width: 768px) {
        margin: 0 0 2px;
        margin-inline-end: 10px;
        flex: 0 0 auto;
    }
    svg {
        height: 12px;
        width: 18px;
        vertical-align: middle;
    }
}
.darkMode {
    ul li,
    .mfooter-copyright {
        color: #cddbe5;
    }
}
