.recentPosts {
    h3 {
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 17px;
        line-height: 1.2;
        color: #000;
        text-align: start;
        padding: 0;
        margin: 17px 0 20px;
    }
    .blog-posts {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
    }
    a.view-all {
        display: inline-block;
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 14px;
        position: relative;
        text-decoration: none;
        color: var(--primary-link-color);
        vertical-align: text-top;
        line-height: 1.35;
        &:hover {
            color: var(--primary-link-active-color);
        }
    }
}
.darkMode h3 {
    color: #fff;
}
