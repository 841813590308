.highlight {
    --padding-right: max(calc(0.4em + var(--mark-indent, 0) * 0.4em), 0px);
    --padding-left: max(calc(0.4em - var(--mark-indent, 0) * 0.4em), 0px);
    --offset-top: calc(50% + var(--mark-offset, 0) * 1em);
    box-decoration-break: clone;
    padding: 0 var(--padding-right) 0 var(--padding-left);
    margin: 0 calc(-1 * var(--padding-right)) 0 calc(-1 * var(--padding-left));
    background-color: transparent;
    background-image: url('./mark.svg#left'), url('./mark.svg#inside'), url('./mark.svg#right');
    background-position: 0 var(--offset-top), 0.4em var(--offset-top), right 0 top var(--offset-top);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: auto calc(var(--mark-h, 1) * 1em), calc(100% - var(--padding-right) - var(--padding-left)) calc(var(--mark-h, 1) * 1em), auto calc(var(--mark-h, 1) * 1em);
    color: inherit;
    position: relative;
    z-index: -1;

    &:empty {
        background: none;
    }
}
.blue {
    color: #fff;
    background-image: url('./mark-blue.svg#left'), url('./mark-blue.svg#inside'), url('./mark-blue.svg#right');
}
.red {
    background-image: url('./mark-red.svg#left'), url('./mark-red.svg#inside'), url('./mark-red.svg#right');
}
.orange {
    background-image: url('./mark-orange.svg#left'), url('./mark-orange.svg#inside'), url('./mark-orange.svg#right');
}
.purple {
    background-image: url('./mark-purple.svg#left'), url('./mark-purple.svg#inside'), url('./mark-purple.svg#right');
}
.navy {
    background-image: url('./mark-navy.svg#left'), url('./mark-navy.svg#inside'), url('./mark-navy.svg#right');
}
.textColor_black {
    color: #14191f;
}
.textColor_yellow {
    color: #ffea00;
}
.textColor_white {
    color: #fff;
}
