.block {
    h3 {
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 17px;
        line-height: 1.2;
        color: #000;
        text-align: start;
        padding: 0;
        margin: 17px 0 20px;
    }
    & > ul {
        width: 100%;
        padding: 0;
        margin: 0 0 20px;
        list-style: none;
        li {
            margin-bottom: 13px;
            a {
                font-family: var(--rubik);
                font-weight: 400;
                font-size: 14px;
                line-height: 1.35;
                color: #202730;
                text-decoration: none;
                display: inline-block;
                &:hover {
                    color: var(--primary-link-active-color);
                }
                mark {
                    background: var(--primary-btn-bg-color);
                    color: var(--primary-btn-text-color);
                    padding: 3px 7px;
                    border-radius: 20px;
                    font-family: var(--rubik);
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0.02em;
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }
    }
}
.darkMode {
    h3 {
        color: #fff;
    }
    & > ul li a {
        color: #cddbe5;
    }
}
