.heading {
    position: relative;
    z-index: 1;
    font-family: var(--source);
    font-weight: 700;
    line-height: 1.4;
    font-size: 30px;
    letter-spacing: 0.02em;
    margin: 0 0 30px;
    --mark-indent: 0.2;
    --mark-h: 1.3;
    br {
        display: none;
        @media screen and (min-width: 980px) {
            display: block;
        }
    }
    &.center {
        text-align: center;
    }
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
}
h1.heading {
    font-weight: 900;
    line-height: 1.25;
    --mark-h: 1.1;
    --mark-indent: 0.2;
}
