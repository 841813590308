.supplemental {
    max-width: 1200px;
    margin: 0 auto;
    padding: 22px 10px 15px;
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    gap: 10px 40px;
    @media screen and (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 1023px) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
}
