html {
    --base-fonts: 'Helvetica', 'Arial', sans-serif;
    --source: 'Source Sans 3', var(--base-fonts);
    --roboto: 'RobotoFlex', var(--base-fonts);
    --rubik: 'Rubik', var(--base-fonts);
    --notoserif: 'Noto Serif', var(--base-fonts);

    &[lang='vi'] {
        --rubik: var(--base-fonts);
        --notoserif: serif;
    }
}
html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--roboto);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;

    /* fonts rendering */
    text-rendering: optimizeLegibility;

    --header-height: 88px;
    @media screen and (min-width: 900px) {
        --header-height: 70px;
    }
}
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
body:has(:modal) {
    overflow: hidden;
}
