.mfooter-social {
    text-align: center;
    padding: 0;
    margin: 10px 0;
    box-sizing: border-box;
    h3 {
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 17px;
        line-height: 1.2;
        color: #000;
        text-align: start;
        margin: 0 0 20px;
        padding: 0;
        @media screen and (min-width: 480px) {
            margin: 0 0 32px;
        }
    }
    .social-list {
        list-style: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        max-width: 250px;
        gap: 0 17px;
        @media screen and (min-width: 768px) {
            margin: 0;
        }
        li {
            padding: 0;
            box-sizing: border-box;
            display: flex;
            a {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                svg {
                    fill: #000;
                    flex: 0 0 auto;
                    padding-bottom: 3px;
                }
                &:hover {
                    svg {
                        fill: var(--primary-link-focus-color, #00baff);
                    }
                }
            }
        }
    }
}

.darkMode {
    h3 {
        color: #fff;
    }
    .social-list li a svg {
        fill: #cddbe5;
    }
}
