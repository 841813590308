/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 12, 2023 */
@font-face {
    font-family: 'PT Serif';
    src: url('../../public/fonts/PTSerif/ptserif-bolditalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'PT Serif';
    src: url('../../public/fonts/PTSerif/ptserif-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'PT Serif';
    src: url('../../public/fonts/PTSerif/ptserif-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'PT Serif';
    src: url('../../public/fonts/PTSerif/ptserif-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
