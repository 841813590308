html {
    --primary-brand-color-300: #33c9ff;
    --primary-brand-color-400: #00baff;
    --primary-brand-color-600: #00a2ff;
    --primary-brand-color-800: #00a2ff;
    --primary-brand-color-alternate-400: #fff;
    --primary-brand-color-alternate-600: #fff;
    --primary-brand-color-alternate-800: #fff;
    --primary-brand-color-alternate-400-light: #cff;

    --secondary-brand-color-400: #ffea00;
    --secondary-brand-color-600: #ffd200;
    --secondary-brand-color-800: #ffd200;
    --secondary-brand-color-alternate-400: #0b212a;
    --secondary-brand-color-alternate-600: #0b212a;
    --secondary-brand-color-alternate-800: #0b212a;

    --primary-background-color: #fff;
    --primary-text-color-800: #202730;
    --primary-text-color-200: #7990a1;

    --primary-opposite-background-color-300: #10303d;
    --primary-opposite-background-color-400: #0b212a;
    --primary-opposite-text-color-800: #202730;
    --primary-opposite-text-color-200: #7990a1;

    --orange-button-text: #fff;
    --orange-color: #fd773b;
    --orange-color-hover: #fa540a;

    --green-button-text: #fff;
    --green-color: #00cd6e;
    --green-color-hover: #1ad27d;
}

.theme-high-contrast {
    --primary-brand-color-300: #38f;
    --primary-brand-color-400: #006aff;
    --primary-brand-color-600: #056dff;
    --primary-brand-color-800: #0e3998;
    --primary-brand-color-alternate-400: #fff;
    --primary-brand-color-alternate-600: #fff;
    --primary-brand-color-alternate-800: #fff;
    --primary-brand-color-alternate-400-light: #ebffff;

    --secondary-brand-color-400: #ffea00;
    --secondary-brand-color-600: #ffd200;
    --secondary-brand-color-800: #ffd200;
    --secondary-brand-color-alternate-400: #0b212a;
    --secondary-brand-color-alternate-600: #0b212a;
    --secondary-brand-color-alternate-800: #0b212a;

    --primary-background-color: #fff;
    --primary-text-color-800: #202730;
    --primary-text-color-200: #677689;

    --primary-opposite-background-color-300: #10303d;
    --primary-opposite-background-color-400: #0b212a;
    --primary-opposite-text-color-800: #202730;
    --primary-opposite-text-color-200: #f8fafc;
    --primary-opposite-link-color: #00baff;
    --primary-opposite-link-hover-color: #fff;

    --orange-color: #ff773b;
    --orange-color-hover: #fa540a;
    --orange-button-text: #202730;

    --green-button-text: #fff;
    --green-color: #008547;
    --green-color-hover: #1a915a;
}

html,
.theme-high-contrast {
    --primary-opposite-link-color: var(--primary-brand-color-400);
    --primary-opposite-link-hover-color: var(--primary-brand-color-800);

    --primary-icon-color: var(--primary-brand-color-400);

    --primary-btn-bg-color: var(--primary-brand-color-400);
    --primary-btn-hover-bg-color: var(--primary-brand-color-800);
    --primary-btn-focus-bg-color: var(--primary-brand-color-800);
    --primary-btn-active-bg-color: var(--primary-brand-color-600);

    --primary-btn-text-color: var(--primary-brand-color-alternate-400);
    --primary-btn-hover-text-color: var(--primary-brand-color-alternate-600);
    --primary-btn-focus-text-color: var(--primary-brand-color-alternate-600);
    --primary-btn-active-text-color: var(--primary-brand-color-alternate-800);

    --primary-link-color: var(--primary-brand-color-400);
    --primary-link-hover-color: var(--primary-brand-color-800);
    --primary-link-focus-color: var(--primary-brand-color-800);
    --primary-link-active-color: var(--primary-brand-color-600);

    --light-text-primary-color: var(--primary-text-color-200);
    --light-text-alternate-primary-color: var(--primary-opposite-text-color-200);

    --placeholder-text-color: var(--primary-text-color-200);
}
