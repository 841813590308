/* stylelint-disable value-list-comma-space-after */
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-basic.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+20-7E, U+A0, U+A2-A3, U+A5, U+A9, U+AD-AE, U+B4, U+2011, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2122, U+2039-203A, U+FEFF;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_fr_it_pt_es.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+E9, U+C9;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-fr_it.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+E8, U+C8, U+D9, U+F9;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_fr_pt.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+E2, U+C2, U+C7, U+E7, U+CA, U+D4, U+EA, U+F4;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_fr_it_pt.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+E0, U+C0;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-it.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+CC, U+EC, U+D2, U+F2;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_pt_es.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+CD, U+DA, U+ED, U+FA, U+C1, U+E1;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_it_pl_pt_es.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+D3, U+F3;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_fr_de_pt_es.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+DC, U+FC;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-br_ru_fr_de_it_pl_pt_es.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+A8, U+AB, U+B8, U+BB, U+2C6, U+2DA, U+2DC, U+201A, U+201E;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-ru.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+400-4FF;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-fr.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+152-153, U+CF, U+EF, U+FF, U+178, U+CB, U+EB, U+CE, U+EE, U+DB, U+FB, U+E6, U+C6;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-de.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+DF, U+E4, U+C4, U+D6, U+F6;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-pt_br.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+C3, U+D5, U+E3, U+F5;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-pl.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+104-107, U+118-119, U+141-144, U+15A-15B, U+179-17C;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-es.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+A1, U+BF, U+D1, U+F1;
}
@font-face {
    font-family: 'Source Sans 3';
    src: url('../../public/fonts/SourceSans3/SourceSans3-complement.woff2');
    font-display: swap;
    font-weight: 200 900;
    font-style: normal;
    unicode-range: U+0, U+D, U+A4, U+A6-A7, U+AA, U+AC, U+AF-B3, U+B5-B7, U+B9-BA, U+BC-BE, U+D0, U+D7, U+DE, U+DD, U+FD, U+E5, U+102-103, U+162-163, U+218-21B, U+15E-15F, U+11E-11F, U+130-131, U+150-151, U+170-171, U+D8, U+F8, U+C5, U+F0,
        U+F7, U+FE, U+100-101, U+108-117, U+11A-11D, U+120-12F, U+132-140, U+145-14F, U+154-159, U+15C-15D, U+160-161, U+164-16F, U+172-177, U+17D-217, U+21C-2C5, U+2C7-2D9, U+2DB, U+2DD-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1,
        U+3A3-3E1, U+3F0-3FF, U+500-52F, U+1AB0-1ABE, U+1C80-1C88, U+1D00-1DF5, U+1DFB-1F15, U+1F18-1F1D, U+1F20-1F45, U+1F48-1F4D, U+1F50-1F57, U+1F59, U+1F5B, U+1F5D, U+1F5F-1F7D, U+1F80-1FB4, U+1FB6-1FC4, U+1FC6-1FD3, U+1FD6-1FDB,
        U+1FDD-1FEF, U+1FF2-1FF4, U+1FF6-1FFE, U+2000-2010, U+2012, U+2015-2017, U+201B, U+201F-2021, U+2023-2025, U+2027-2038, U+203B-2064, U+2066-2071, U+2074-208E, U+2090-209C, U+20A0-20AB, U+20AD-20BF, U+20F0, U+2100-2121, U+2123-215F,
        U+2184, U+2189, U+2C60-2C7F, U+2DE0-2E44, U+A640-A69F, U+A700-A7AE, U+A7B0-A7B7, U+A7F7-A7FF, U+A92E, U+AB30-AB65, U+FB00-FB06, U+FE00, U+FE20-FE2F, U+FFFC-FFFD, U+FFFF;
}
